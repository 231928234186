import { ValidatorFn, AbstractControl } from "@angular/forms";

interface RegexDetail {
  regex: RegExp;
  isValid: boolean;
  error: { [key: string]: any };
}

export class CustomValidators {
  static passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any }[] | null => {
      if (!control.value) {
        /**
         * if control is empty return no error
         */
        return null;
      }
      /** As per Auth0 policy there are 2 validation group
       * 1. We need to check length always (At least 8 length).
       * 2. We need to check 4 format conditions and must fulfill 3 condition.
       */

      /**
       * 1. Regex to check length
       */
      let regexLengthCreation: RegexDetail = {
        regex: /.{8,}/,
        isValid: false,
        error: { minLength: true },
      };

      /**
       * 2. Regex array for format creation
       */
      let regexFormatCreation: RegexDetail[] = [
        {
          regex: /\d/,
          isValid: false,
          error: { hasNumber: true },
        },
        {
          regex: /[A-Z]/,
          isValid: false,
          error: { hasCapitalCase: true },
        },
        {
          regex: /[a-z]/,
          isValid: false,
          error: { hasSmallCase: true },
        },
        {
          regex: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
          isValid: false,
          error: { hasSpecialCharacters: true },
        },
      ];

      let errors: { [key: string]: any }[] = [];

      /**
       * Validate length
       */
      if (!regexLengthCreation.regex.test(control.value)) {
        regexLengthCreation.isValid = false;
        errors.push(regexLengthCreation.error);
      }

      /**
       * Validate format creation
       */
      regexFormatCreation.forEach((regexDetail) => {
        regexDetail.regex.test(control.value)
          ? (regexDetail.isValid = true)
          : (regexDetail.isValid = false);
      });
      let formatCreationErrors = regexFormatCreation
        .filter((regex) => regex.isValid === false)
        .map((regex) => regex.error);

      /** It is required that any 3 condition need to be fulfilled.
       *  If number of format creation error are greater than one that means not fulfilled 3 condition.
       */
      let minimumLength = 1;

      if (formatCreationErrors && formatCreationErrors.length > minimumLength) {
        errors = errors.concat(formatCreationErrors);
      }
      /**
       * If any error exists then validation failed and return it.
       */
      let emptyErrorNumber = 0;

      if (errors && errors.length > emptyErrorNumber) {
        return errors;
      }

      return null;
    };
  }
}
