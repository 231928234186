import { NgModule } from "@angular/core";
import { ModuleWithProviders, Optional, SkipSelf } from "@angular/core";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { LoginComponent } from "./auth/login/login.component";
import { LoginCallbackComponent } from "./auth/logincallback/login.callback.component";
import { SignUpComponent } from "./signup/signup.component";

@NgModule({
  declarations: [LoginComponent, LoginCallbackComponent, SignUpComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    SharedModule
  ],
  providers: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}
