export const locale = {
  lang: "en",
  localeData: {
    accountTitle: "Account",
    languagesetting: "Language Settings",
    fullName: "Full Name",
    displayName: "Display Name",
    login: {
      capital: "LOGIN",
      lower: "login",
      pascal: "Login",
      spaceErrorMessage:
        "You are not associated with any space, Please contact your system administrator",
      signInHeaderMessage: "Sign in failed!",
      spaceNotFound:
        "Failed to get space information, Please contact your system administrator",
      loginError: "Invalid Email or Password.",
      userNotFound: "User not found. Please login again.",
      error: {
        dismiss: "Dismiss",
        rateLimit: {
          informationMessage: "Too many users log in at the same time.",
          instructionMessage: "Please try again in a few minutes."
        },
        unregistered: {
          informationMessage: "The e-mail address is not yet registered.",
          instructionMessage: "Please ask your space owner to invite you to Space."
        },
        initialization: {
          informationMessage: "Failed to link your account to DIVA Account.",
          instructionMessage: "Please try again or report this issue to your IT support."
        },
        internal: {
          informationMessage: "Failed to log in due to an error.",
          instructionMessage: "Please try again or report this issue to your IT support."
        },
        fallback: {
          informationMessage: "Something went wrong.",
          instructionMessage: "Please try again or report this issue to your IT support."
        },
        getUserFailed: {
          informationMessage: "Failed to get user information.",
          instructionMessage: "Please try again or report this issue to your IT support."
        },
        invalidDomain: {
          informationMessage: "The e-mail address does not use external authentication.",
          instructionMessage: "Please try again with an e-mail that can use external authentication."
        },
        sessionExpired: {
          informationMessage: "Your session has expired.",
          instructionMessage: "Please log in again."
        },
        blocked: {
          informationMessage: "Your account has been blocked.",
          instructionMessage: "To unblock your account, please contact your IT support."
        }
      }
    },
    signUp: {
      title: "Member Sign Up",
      signUpButton: "Sign Up",
      password: "Password",
      confirmPassword: "Confirm Password",
      confirmPasswordMismatchError: "Password and Confirm Password must match.",
      signUpDone: "Sign up done successfully",
      invalidRequest: "Invalid request for sign up.",
      invalidRequestData: "Invalid sign up request data.",
      signupAlreadyDone: "You have already signed up.",
      signupError: "Some error occured while signup.",
      weakPassword: "Password is too weak.",
    },
    group: "Group",
    overview: "Overview",
    notification: "Notifications",
    name: {
      first: "First Name",
      last: "Last Name",
      nick: "Nick Name",
      full: "Full Name",
      display: "Display Name",
    },
    loginId: "Login ID (Email)",
    password: {
      password: "Password",
      forgot: "Forgot Password?",
      confirm: "Password (Confirm)",
      confirmIsRequired: "Password confirmation",
      match: "Password must match",
      forgetPasswordEmailMessage:
        "An mail has been sent to your id, please click on the link in the mail to reset your password!",
      forgetPasswordDialogHeading: "Password reset mail sent",
      forgetPasswordErrorMessage: "Password reset failed!",
    },
    rememberMe: "Remember Me",
    enterEmail: "Please enter a valid email address",
    logout: "Logout",
    profile: {
      my: "My Profile",
      profile: "Profile",
      edit: "Edit Profile",
    },
    editProfile: {
      fullNamePlaceholder: "Enter full name",
      displayNamePlaceholder: "Enter display name",
      emailPlaceholder: "user@email.com",
      removePictureHeader: "Remove Profile Photo?",
      removePictureConfirmationDialog:
        "Are you sure you want to remove your photo? We'll replace it with a default avatar.",
      edit: "Edit Profile",
      imageUpload: {
        tooLarge:
          "The file size of your image is too large. Please select an image under 1 megabyte.",
        invalidFileType: "Invalid file type",
        maxFileSize: "Max File size: 1 MB",
        success: "Profile picture has been saved!",
      },
      emailChangeTitle: "Change Email Address?",
      emailChangeText:
        "You will be prompted to relogin into DIVA Cloud,\n If you change your email address.",
      emailChangeButtonText: "Change Email and Relogin",
      errorMessage: "There is some problem in updating the profile data.",
      emailErrorMessage:
        "There is some problem in updating the profile data. Email might already exist.",
    },
    editProfileEnterName: "Enter the name you would like to use in Conflow",
    editProfileError: "Failed to upload profile info",
    security: {
      title: "Security & Login",
      mfa: {
        title: "Multi-Factor Authentication (MFA)",
        status: "MFA Status",
        enableTitle: "Enabling Multi-Factor Authentication",
        disableTitle: "Disabling Multi-Factor Authentication",
        resetTitle: "Resetting MFA",
        enable: "Enable",
        disable: "Disable",
        reset: "Reset",
        enabled: "Enabled",
        disabled: "Disabled",
        description:
          "Set up 2-step verification code every time you log in to make your DIVA Account more secure.",
        directions:
          "In order to enable this feature, you will need to log out and log back in to your account and then set up the Multi-Factor Authentication (MFA).",
        resetdescription:
          "Resetting MFA. You will need to log out and log back in to your account and then set up a new device.",
        note: "Note: Before proceeding, make sure to prepare a working device with the authentication app installed. Otherwise, you will not be able to set up MFA.",
        reLogin: "Log back in & set up",
        error: "Error setting MFA",
        unsupported:
          "Your account does not support Multi-Factor Authentication feature.",
        unsupportedDescription:
          "This feature only works with DIVA Account. For more details, please contact your space owner.",
        disableDescription:
          "We will disable Multi-Factor Authentication (MFA) feature. You will no longer receive verification codes every time you log in.",
        enableAgain:
          "To enable MFA feature again, simply click the \"enable\" button in Security & Login > Multi-Factor Authentication.",
        disableSuccess: "Multi-Factor Authentication has been disabled",
      },
      loginActivity: {
        title: "Login Activity",
        description: "You should recognize each of these recent activites. If an activity looks unfamiliar, please change your password immediately. \n It may take some time to update the log data.",
        appColumnHeader: "Application Name",
        dateColumnHeader: "Date and Time",
        loginColumnHeader: "Login",
        deviceColumnHeader: "Device",
        ipAddressColumnHeader: "IP Address",
        loginSuccessStatus: "Success",
        loginFailedStatus: "Failed",
        expandButtonLabel: "See more",
        collapseButtonLabel: "See less",
        emptyLoginActivityDescription: "There is no activity.",
        iconLang: "icon-en"
      }
    },
    save: "Save",
    saving: "Saving..",
    EDIT: "Edit",
    REMOVE: "Remove",
    DOWNLOAD: "Download",
    Actions: "Actions",
    Delete: "Delete",
    cancel: "Cancel",
    confirm: "Confirm",
    status: "Status",
    activity: "Activity",
    directory: "Directory",
    offNotification: "Do Not Disturb",
    yourFile: "Your Files",
    ok: "OK",
    register: "Register",
    nickname: "Nick Name",
    uploadPicture: "Upload Picture",
    changePicture: "Change Picture",
    removePicture: "Remove Picture",
    menu: "menu",
    "translate-en": "Translate to English",
    "translate-ja": "Translate to Japanese",
    account: {
      have: "Already have an account",
      dont: "Do not have an account",
      create: {
        capital: "CREATE AN ACCOUNT",
        lower: "create an account",
        pascal: "Create an account",
      },
    },
    resetPassword: {
      capital: "RECOVER YOUR PASSWORD",
      lower: "recover your password",
      pascal: "Recover your password",
    },
    resetLink: {
      capital: "SEND RESET LINK",
      lower: "send reset link",
      pascal: "Send reset link",
    },
    backToLogin: "Go back to login",
    acceptTerms: "I read and accept",
    termsCondition: "terms and conditions",
    select: "Select",
    send: "Send",
    email: "Email",
    searchField: "Search Anything Here",
    validation: {
      isRequired: "{{field}} is required",
      fullNameIsRequired: "Full name is required.",
      emailIsRequired: "Email is required.",
      emailIsIncorrect: "Please enter the email in correct format.",
      passwordIsRequired: "Please enter password.",
      confirmPasswordIsRequired: "Please enter confirm password.",
      passwordMismatchError: "Password and Confirm Pasword should match.",
      passwordLengthMessage: "Use at least 8 characters.",
      passwordFormatValidation: {
        mainMessage: "Include at least 3 of the following:",
        useNumberMessage: "Number (1, 2, 3...).",
        specialCharacterMessage: "Special character (!, @, #...).",
        uppercaseCharacterMessage: "Uppercase character (A - Z).",
        lowecaseCharacterMessage: "Lowercase character (a - z).",
      },
    },
    conversation: {
      inputMessage: "Type your message here. Type @ to mention someone.",
      uploadFile: "Upload file",
      includeFile: "Include File",
      edited: "edited",
      messageDeleteConfirmationDialogHeader: "Delete Message",
      messageDeleteConfirmationMessage:
        "Are you sure you want to delete this message? This cannot be undone.",
      messageDelete: "(This message was deleted)",
      fileDelete: "(The file was deleted)",
      messageAndFileDelete: "(This message and file was deleted)",
    },
    channel: {
      tabs: {
        conversation: "Conversation",
        files: "Files",
        status: "Status",
        members: "Members",
      },
      status: {
        select: "Select status",
        statusConfirmMessage:
          "Status keys are changed. All statuses are reset. OK?",
      },
    },
    files: {
      fromDate: "From date",
      toDate: "To date",
      fileName: "Name",
      fileType: "Type",
      fileSize: "Size",
      uploadedOn: "Uploaded On",
      uploadedBy: "Uploaded By",
      uploadUserName: "User Name",
      tags: "Tags",
      fileDeletionConfirmationMessage:
        "Are you sure you want to delete this file?",
      fileDeleteConfirmationDialogHeader: "Delete File",
      file: "File",
      fileSelectionMessage: "Please choose a file",
      fileUploadErrorMessage: "Failed to upload file to server",
      filePreviewErrorMessage: "Failed to get file preview",
      ScrollLoadingText: "Fetching files...",
      NoData: "No Data Found",
      RowFilterText: "Search",
      filters: {
        simpleTagFilter: "Tag Value Simple Filter",
        simpleTagCategoryFilter: "Tag Category Simple Filter",
        TagcategryHierarchyFilter: "Tag Category Hierarchy Filter",
      },
      editTags: "Edit Tags",
      renameFile: "Rename File",
    },
    fileUpload: {
      title: "Upload file",
      chooseFile: "Choose a file",
      addMessageToFile: "Add message to file",
      addTagsToFile: "Add tags to file",
      upload: "Upload",
      fileUploadMaxSizeErrorMessage: "This file is too large to upload",
      storageLimitExceededMessage:
        "File storage limit exceeded. Please contact administrator.",
      invalidFileNameMessage:
        "Invalid file name. Supported file names of 255 characters or less.\"/\" or \"\\\", trailing spaces, and the special names \".\" and \"..\" are not allowed.",
      fileNameTooLongMessage:
        "File name is too long. Supported file names of 255 characters or less.",
      fileAlreadyExistModelTitle: "File Already Exist",
      fileAlreadyExistConfirmMessage:
        "Files {files} already exist. Do you want to replace?",
      sameFileNameNotAllowed: "Please select files with different name",
      supportedImageFiles: "Supported files: .png, .jpg",
      maxImageSize: "Maximum file size: 1 megabyte",
    },
    revokeToken: {
      menuMessage: "Revoke Token",
      confirmTitle: "Revoking Tokens",
      confirmMessage:
        "Revoking tokens will terminate all applications \n  you have opened from previous sessions or other devices.",
      confirmButtonText: "Revoke",
      successMessage: "Token has been revoked.",
      errorMessage: "There is some error in token revocation.",
      noDevicesFound: "No devices found.",
    },
  },
};