import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { SignUpModel } from "./models/signup.model";
import { SharedSettings } from "@app/shared/setting/shared-settings";

@Injectable({
  providedIn: "root"
})
export class SignUpService {
  constructor(private httpClient: HttpClient) {

  }

  signUp(signUpModel: SignUpModel): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.append("NoAuthValidate", "true"); // Add this header to skip auth0 authorization by interceptor
    return this.httpClient.post(`${SharedSettings.accountServiceUrl }management/users:signup`, signUpModel, { headers: headers });
  }
}
