import { Component, OnInit } from "@angular/core";
import { SignUpService } from "./signup.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SignUpModel } from "./models/signup.model";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CustomValidators } from "@app/shared/validators/custom-validators";
import { AlertService } from "diva-lib";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignUpComponent implements OnInit {
  private readonly SIGNUP_KEY_PARAM_NAME: string = "key";
  private readonly SIGNUP_EMAIL_PARAM_NAME: string = "email";
  private readonly SIGNUP_REDIRECT_URL_PARAM_NAME: string = "redirecturl";
  private readonly LOGIN_URL: string = "/index";

  signUpForm: FormGroup;
  signUpKey: string;
  email: string;
  redirectUrl: string;
  isLoading: boolean = false;

  constructor(
    private signUpService: SignUpService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    if (
      !this.route.snapshot.queryParamMap.has(this.SIGNUP_KEY_PARAM_NAME) ||
      !this.route.snapshot.queryParamMap.has(this.SIGNUP_EMAIL_PARAM_NAME)
    ) {
      this.translateService.get("signUp.invalidRequest").subscribe(res =>
        this.alertService.showErrorSnakBar(res));

      this.router.navigateByUrl(this.LOGIN_URL);
    }
    // Fetch sign up key and email from requested url
    // Here expecting both values are in encoded uri value.
    this.signUpKey = decodeURIComponent(
      this.route.snapshot.queryParamMap.get(this.SIGNUP_KEY_PARAM_NAME)
    );
    this.email = decodeURIComponent(
      this.route.snapshot.queryParamMap.get(this.SIGNUP_EMAIL_PARAM_NAME)
    );
    let url = this.route.snapshot.queryParamMap.get(
      this.SIGNUP_REDIRECT_URL_PARAM_NAME
    );

    this.redirectUrl = url && url.trim() !== "" ? decodeURIComponent(url) : "";

    // Create sign up form group
    this.signUpForm = new FormGroup(
      {
        fullName: new FormControl("", Validators.required),
        displayName: new FormControl(""),
        email: new FormControl(
          { value: this.email, disabled: true },
          Validators.required
        ),
        password: new FormControl("", [
          Validators.required,
          CustomValidators.passwordValidator(),
        ]),
        confirmPassword: new FormControl("", [Validators.required]),
      },
      this.pwdMatchValidator
    );
    if (document.getElementById("global-spinner")) {
      document.getElementById("global-spinner").style.display = "none";
    }
  }

  public pwdMatchValidator(frm: FormGroup) {
    if (frm.get("confirmPassword").value) {
      if (frm.get("password").value === frm.get("confirmPassword").value) {
        frm.controls["confirmPassword"].setErrors(null);
        return null;
      }
      frm.controls["confirmPassword"].setErrors({ mismatch: true });
      return { mismatch: true };
    }
  }

  /** *
   *Sign up method
   * */
  signup(): void {
    this.isLoading = true;
    // Create sign up model
    let signUpModel: SignUpModel = {
      fullName: this.signUpForm.get("fullName").value,
      nickName: this.signUpForm.get("displayName").value,
      password: this.signUpForm.get("password").value,
      email: this.email,
      signUpKey: this.signUpKey,
    };
    // Call sign up api to save data

    this.signUpService.signUp(signUpModel).subscribe({
      next: (response) => {
        this.translateService.get("signUp.signUpDone").subscribe(res =>
          this.alertService.showSuccessSnakBar(res));
        this.isLoading = false;
        this.signUpForm.reset();
        if (this.redirectUrl) {
          this.redirectToUrl(this.redirectUrl);
        } else {
          this.router.navigateByUrl(this.LOGIN_URL);
        }
      },
      error: (error) => {
        let badRequestStatusCode = 400;
        let conflictStatusCode = 409;

        if (error.status === badRequestStatusCode) {
          if (
            error.error &&
            error.error.title &&
            error.error.title.toLowerCase() === "weak password"
          ) {
            this.translateService.get("signUp.weakPassword").subscribe(res =>
              this.alertService.showErrorSnakBar(res));
          } else {
            this.translateService.get("signUp.invalidRequestData").subscribe(res =>
              this.alertService.showErrorSnakBar(res));
          }
        } else if (error.status === conflictStatusCode) {
          this.translateService.get("signUp.signupAlreadyDone").subscribe(res =>
            this.alertService.showErrorSnakBar(res));
        } else {
          this.translateService.get("signUp.signupError").subscribe(res =>
            this.alertService.showErrorSnakBar(res));
        }
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
  redirectToUrl(url: string) {
    window.location.assign(url);
  }
}
