export const languages = [
  {
    id: "en",
    title: "English",
    flag: "us"
  },
  {
    id: "ja",
    title: "日本語",
    flag: "ja"
  }
];
