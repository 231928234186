import { languages } from "@shared/const/lang";
import { AppConfig } from "@app/config/app.config";

export class SharedSettings {
  public static rewriteUrl(url: string): string {
    return url;
  }

  public static get defaultLanguage(): string {
    return "en";
  }

  public static get getLanguagesIdInArray(): Array<string> {
    return languages.map(language => language.id);
  }

  public static get launchDarklyClientID(): any {
    return `${AppConfig.settings.launchDarklyClientID}`;
  }

  // --------------individual service urls --------------------------//
  public static get accountServiceUrl(): any {
    return `${AppConfig.settings.accountServiceUrl}`;
  }

  public static get defaultAvatarUrl(): any {
    return `${AppConfig.settings.defaultAvatarUrl}`;
  }

  public static get domain(): any {
    return `${AppConfig.settings.domain}`;
  }
  public static get clientId(): any {
    return `${AppConfig.settings.clientId}`;
  }
  public static get audience(): any {
    return `${AppConfig.settings.audience}`;
  }
}
