import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ErrorCodes } from "../errorcodes";

@Component({
  selector: "app-login.callback",
  templateUrl: "./login.callback.component.html",
  styleUrls: ["./login.callback.component.scss"]
})
export class LoginCallbackComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    /*
    queryParams: ?error=unauthorized&error_description=user%20is%20blocked&state=Y3ZsNjAtXzVEQVBOZ1pyZkxBbG1fd056Mm9yUUpOZ1hzR2ZhZ0VqWmouWQ%3D%3D
    {
      error: unauthorized
      error_description: user%20is%20blocked
      state: 3ZsNjAtXzVEQVBOZ1pyZkxBbG1fd056Mm9yUUpOZ1hzR2ZhZ0VqWmouWQ%3D%3D
    }
    */
    const queryParams = this.activatedRoute.snapshot.queryParams;

    // change redirect destination by error key in the query parameter
    if (queryParams["error"]) {
      this.redirectToIndex(queryParams); // failed
    } else {
      this.router.navigate(["dashboards"]); // success
    }
  }

  redirectToIndex(params: Params) {
    // error code detection
    let errcode = ErrorCodes.ELSE;

    for(const code in ErrorCodes) {
      // search keyword in error or error_description
      if ((params["error"] + params["error_description"]).includes(`[${ErrorCodes[code]}]`)) {
        errcode = ErrorCodes[code];
        break;
      }
    }

    // handle the error the case of the user has been blocked
    if (errcode === ErrorCodes.ELSE && (params["error"] + params["error_description"]).includes("user is blocked")) {
      errcode = ErrorCodes["BLOCKED"];
    }

    // redirect
    this.router.navigate(["index"], { queryParams: { errorcode: errcode } });
  }
}