<div class="signUp-page">
  <div class="signUp-section">
    <div class="signUp-welcome">
      <div class="welcome-inner">
        <span>Welcome to</span>
        <img src="assets/images/Diva-Cloud-Logo-2.svg">
      </div>
    </div>
    <div class="signUp-form">
      <div class="form-inner">
        <p class="signUp-head">{{'signUp.title' | translate}}</p>
        <form [formGroup]="signUpForm" autocomplete="off">
          <mat-form-field class="form-field">
            <mat-label>{{'fullName' | translate}}</mat-label>
            <input matInput maxlength="50" placeholder="{{'fullName' | translate}}" name="fullName" formControlName="fullName" class="form-control">
            <mat-error class="fullNameRequired" *ngIf="signUpForm.get('fullName').invalid">{{'validation.fullNameIsRequired' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field class="form-field">
            <mat-label>{{'displayName' | translate}}</mat-label>
            <input matInput maxlength="50" placeholder="{{'displayName' | translate}}" name="displayName" formControlName="displayName">
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-field">
            <mat-label>{{'email' | translate}}</mat-label>
            <input matInput placeholder="{{'email' | translate}}" name="email" formControlName="email">
          </mat-form-field>

          <mat-form-field class="form-field">
            <mat-label>{{'signUp.password' | translate}}</mat-label>
            <input matInput placeholder="{{'signUp.password' | translate}}" type="password" name="password" formControlName="password" autocomplete="new-password"/>
            <mat-error *ngIf="signUpForm.get('password').invalid">
              <div class="requiredDiv" *ngIf="signUpForm.get('password').errors.required"> {{'validation.passwordIsRequired' | translate}}</div>
              <ul class="formatError" *ngIf="signUpForm.get('password').errors[0]">
                <li class="lengthError" [class.passwordSuccess]="!signUpForm.get('password').errors[0].minLength">{{'validation.passwordLengthMessage' | translate}}</li>
                <li class="formatError" [class.passwordSuccess]="!signUpForm.get('password').errors[1]">
                  {{'validation.passwordFormatValidation.mainMessage' | translate}}
                  <ul class="formatDetail" [class.passwordSuccess]="!signUpForm.get('password').errors[1]">
                    <li>{{'validation.passwordFormatValidation.useNumberMessage' | translate}}</li>
                    <li>{{'validation.passwordFormatValidation.specialCharacterMessage' | translate}}</li>
                    <li>{{'validation.passwordFormatValidation.uppercaseCharacterMessage' | translate}}</li>
                    <li>{{'validation.passwordFormatValidation.lowecaseCharacterMessage' | translate}}</li>
                  </ul>
                </li>
              </ul>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field">
            <mat-label>{{'signUp.confirmPassword' | translate}}</mat-label>
            <input matInput placeholder="{{'signUp.confirmPassword' | translate}}" type="password" name="confirmPassword" formControlName="confirmPassword"  autocomplete="new-password">
            <mat-error *ngIf="signUpForm.get('confirmPassword').invalid">
              <div class="requiredCnfPwd" *ngIf="signUpForm.get('confirmPassword').errors.required"> {{'validation.confirmPasswordIsRequired' | translate}}</div>
              <div class="passwordMismatch" *ngIf="signUpForm.get('confirmPassword').errors.mismatch"> {{'validation.passwordMismatchError' | translate}}</div>
            </mat-error>
          </mat-form-field>
          <div class="button-row">
            <button mat-raised-button color="primary" class="signup-button" (click)="signup()" [disabled]="!signUpForm.valid || isLoading">
              <span *ngIf="!isLoading"> {{'signUp.signUpButton' | translate}}</span>
              <span class="spinner-div" *ngIf="isLoading">
                <mat-spinner diameter="18" class="spinner"></mat-spinner>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
