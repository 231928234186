import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "@shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { UnauthorizedRequestInterceptor } from "./shared/http-interceptor.service";
import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { AuthGuard } from "@auth0/auth0-angular";
import { AppConfig } from "./config/app.config";
import { CommonModule } from "@angular/common";
import { OverlayContainer, FullscreenOverlayContainer } from "@angular/cdk/overlay";
import { DivaLibModule } from "diva-lib";
import { AuthModule } from "@auth0/auth0-angular";
import { AuthConfigService, AuthHttpInterceptor } from "@auth0/auth0-angular";
import { SharedSettings } from "./shared/setting/shared-settings";

export let initializeApp = (appConfig: AppConfig) => {
  return () => appConfig.load();
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    CoreModule.forRoot(),
    SharedModule,
    CommonModule,
    DivaLibModule,
    AuthModule.forRoot(),
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig, HttpClient], multi: true
    },
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedRequestInterceptor, multi: true },
    { provide: OverlayContainer,useClass: FullscreenOverlayContainer },
    {
      provide: AuthConfigService,
      useFactory: () => ({
        domain: SharedSettings.domain,
        clientId: SharedSettings.clientId,
        authorizationParams: {
          audience: SharedSettings.audience,
          /* eslint-disable camelcase */
          redirect_uri: `${window.location.origin }/logincallback`,
          ui_locales: localStorage.getItem("localStoreLang"),
          /* eslint-enable camelcase */
          httpInterceptor: {
            allowedList: [`${SharedSettings.audience }*`],
          },
        },
      }),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
