import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { SharedSettings } from "../setting/shared-settings";
import { map, catchError } from "rxjs/operators";
import { User } from "../models/user";

@Injectable({
  providedIn: "root"
})
export class GlobalService {
  user: User;
  showLoadingScreen: boolean = false;
  constructor(private httpClient: HttpClient) { }

  getUser(): Observable<any> {
    return this.httpClient.get(`${SharedSettings.accountServiceUrl }management/users/me`)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          throw (error);
        })
      );
  }

  getUserSpaces(): Observable<any> {
    return this.httpClient.get(`${SharedSettings.accountServiceUrl }spaces`)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          throw (error);
        })
      );
  }

  setUser(user) {
    this.user = user;
  }

  getUserLoginHistory(): Observable<any> {
    return this.httpClient.get(`${SharedSettings.accountServiceUrl }management/users/me/logs`)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          throw (error);
        })
      );
  }
}
