export const locale = {
  lang: "ja",
  localeData: {
    accountTitle: "アカウント設定",
    languagesetting: "言語設定",
    fullName: "氏名",
    displayName: "表示名",
    login: {
      capital: "ログイン",
      lower: "ログイン",
      pascal: "ログイン",
      spaceErrorMessage:
        "利用できるスペースがありません。管理者にお問い合わせください。",
      signInHeaderMessage: "ログインに失敗しました。",
      spaceNotFound:
        "スペース情報が取得できませんでした。管理者にお問い合わせください。",
      loginError: "ユーザー名またはパスワードが間違っています。",
      userNotFound:
        "ユーザーが見つかりませんでした。もう一度ログインしてください。",
      error: {
        dismiss: "閉じる",
        rateLimit: {
          informationMessage: "多くのユーザーが同時にログインしました。",
          instructionMessage: "数分後にもう一度お試しください。",
        },
        unregistered: {
          informationMessage: "メールアドレスが未登録です。",
          instructionMessage: "スペースオーナーにスペースへの招待を依頼してください。",
        },
        initialization: {
          informationMessage: "お客様のアカウントでDIVAアカウントへのリンクを失敗しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。",
        },
        internal: {
          informationMessage: "エラーによりログインに失敗しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。"

        },
        fallback: {
          informationMessage: "エラーが発生しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。"
        },
        getUserFailed: {
          informationMessage: "ユーザー情報の取得に失敗しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。"
        },
        invalidDomain: {
          informationMessage: "このメールアドレスは外部認証を利用できません。",
          instructionMessage: "外部認証を利用できるメールアドレスで再度お試しください。"
        },
        sessionExpired: {
          informationMessage: "セッションの有効期限が切れました。",
          instructionMessage: "再度ログインしてください。"
        },
        blocked: {
          informationMessage: "このアカウントはブロックされています。",
          instructionMessage: "アカウントのブロックを解除するには、ITサポートにお問い合わせください。"
        }
      },
    },
    signUp: {
      title: "サインアップ",
      signUpButton: "サインアップ",
      password: "パスワード",
      confirmPassword: "パスワード（確認）",
      confirmPasswordMismatchError: "パスワードを正しく入力してください。",
      signUpDone: "サインアップに成功しました。",
      invalidRequest: "不正なリクエストによりサインアップに失敗しました。",
      invalidRequestData:
        "サインアップに失敗しました。入力が誤っているか既にサインアップ済です。",
      signupAlreadyDone: "サインアップは成功しています。",
      signupError:
        "サインアップでエラーが発生しました。管理者にお問い合わせください。",
      weakPassword: "Password is too weak.",
    },
    group: "グループ",
    overview: "概要",
    notification: "通知",
    name: {
      first: "名",
      last: "姓",
      nick: "ニックネーム",
      full: "氏名",
      display: "表示名",
    },
    loginId: "ログインID（電子メール）",
    password: {
      password: "パスワード",
      forgot: "パスワードをお忘れですか？",
      confirm: "パスワード（確認）",
      confirmIsRequired: "パスワードの確認",
      match: "パスワードが一致している必要があります",
      forgetPasswordEmailMessage:
        "メールを送信しました。メール内のリンクをクリックしてパスワードを再設定してください。",
      forgetPasswordDialogHeading: "パスワードリセットメールを送信しました",
      forgetPasswordErrorMessage: "パスワードリセットに失敗しました",
    },
    rememberMe: "ログイン情報を保存する",
    enterEmail: "有効なメールアドレスを入力してください",
    logout: "ログアウト",
    profile: {
      my: "私のプロフィール",
      profile: "プロフィール",
      edit: "プロフィール編集",
    },
    editProfile: {
      fullNamePlaceholder: "氏名を入力してください",
      displayNamePlaceholder: "表示名を入力してください",
      emailPlaceholder: "user@email.com",
      removePictureHeader: "写真を削除しますか?",
      removePictureConfirmationDialog:
        "写真を本当に削除してよろしいですか? 写真を削除すると初期画像に戻ります。",
      edit: "プロフィール編集",
      imageUpload: {
        tooLarge: "ファイルサイズが大きすぎます。１MBまでです。",
        invalidFileType:
          "サポートされていないフォーマットです。PNG、JPGが選択できます。",
        maxFileSize: "ファイルサイズ上限: 1MB",
        success: "プロフィール画像を保存しました。",
      },
      emailChangeTitle: "メールアドレスを変更しますか？",
      emailChangeText:
        "メールアドレスを変更すると、\n DIVA Cloud に再ログインする必要があります。",
      emailChangeButtonText: "メールアドレスを変更して再ログインする",
      errorMessage: "プロフィールの更新に失敗しました。",
      emailErrorMessage:
        "プロフィールの更新に失敗しました。メールアドレスが登録済の可能性があります。",
    },
    editProfileEnterName: "表示したい名前を入力してください",
    editProfileError: "プロフィールの更新に失敗しました",
    security: {
      title: "セキュリティとログイン",
      mfa: {
        title: "多要素認証（MFA）",
        status: "MFA設定",
        enableTitle: "MFAを有効にする",
        disableTitle: "MFAを無効にする",
        resetTitle: "MFAをリセットする",
        enable: "有効にする",
        disable: "無効にする",
        reset: "リセットする",
        enabled: "有効",
        disabled: "無効",
        description:
          "DIVA Accountをより安全にするために、ログインのたびに2段階認証コードを設定します。",
        directions:
          "この機能を有効にするためには、再ログインしてMFAを設定する必要があります。",
        resetdescription:
          "MFAを初期化します。ログアウトしてアカウントに再度ログインしてから、新しい端末を登録してください。",
        note: "注意：続行するには認証アプリをインストールしたデバイスを用意してください。用意できない場合は、ログインできなくなります。",
        reLogin: "ログアウトしてMFAを設定",
        error: "MFAの設定に失敗しました。",
        unsupported: "このアカウントではMFA機能を利用することはできません。",
        unsupportedDescription:
          "この機能はDIVA Accountでのみ利用できます。詳細は管理者にご連絡ください。",
        disableDescription:
          "MFAを無効化します。以降はログインのたびに認証コードを送信しません。",
        enableAgain:
          "再度MFAを有効にするには、この画面で「有効にする」ボタンをクリックします。",
        disableSuccess: "MFAを無効化しました。",
      },
      loginActivity: {
        title: "ログインアクティビティ",
        description: "最近のアクティビティを確認してください。覚えがないものがあれば、パスワードを変更してください。 \n ログデータ更新に時間がかかることがあります。",
        appColumnHeader: "アプリ名",
        dateColumnHeader: "日時",
        loginColumnHeader: "ログイン",
        deviceColumnHeader: "デバイス",
        ipAddressColumnHeader: "IPアドレス",
        loginSuccessStatus: "成功",
        loginFailedStatus: "失敗",
        expandButtonLabel: "もっと見る",
        collapseButtonLabel: "表示を減らす",
        emptyLoginActivityDescription: "アクティビティはありません。",
        iconLang: "icon-ja"
      }
    },
    save: "保存する",
    saving: "保存中..",
    EDIT: "編集",
    REMOVE: "削除",
    Actions: "アクション",
    Delete: "削除",
    cancel: "キャンセル",
    confirm: "確認",
    status: "状態",
    activity: "アクティビティ",
    directory: "ディレクトリ",
    offNotification: "通知しない",
    yourFile: "あなたのファイル",
    ok: "OK",
    register: "登録",
    nickname: "ニックネーム",
    uploadPicture: "写真を更新する",
    changePicture: "写真を更新する",
    removePicture: "写真を削除する",
    menu: "メニュー",
    "translate-en": "Translate to English",
    "translate-ja": "Translate to Japanese",
    account: {
      have: "すでにアカウントをお持ちですか",
      dont: "アカウントを持っていない",
      create: {
        capital: "アカウントを作成する",
        lower: "アカウントを作成する",
        pascal: "アカウントを作成する",
      },
    },
    resetPassword: {
      capital: "パスワードリセット",
      lower: "パスワードリセット",
      pascal: "パスワードリセット",
    },
    resetLink: {
      capital: "メールを送信する",
      lower: "メールを送信する",
      pascal: "メールを送信する",
    },
    backToLogin: "ログイン画面に戻る",
    acceptTerms: "規約に同意します",
    termsCondition: "規約と条件",
    select: "選択",
    send: "投稿",
    tenant: "テナント",
    email: "電子メール",
    searchField: "何でもここで検索",
    validation: {
      isRequired: "{{field}} は必須です",
      fullNameIsRequired: "氏名は必須です",
      emailIsRequired: "メールアドレスは必須です",
      emailIsIncorrect: "不正なメールアドレスです。正しく入力してください。",
      passwordIsRequired: "パスワードを入力してください",
      confirmPasswordIsRequired: "確認パスワードを入力してください。",
      passwordMismatchError:
        "パスワードとPaswordの確認が一致する必要があります",
      passwordLengthMessage: "少なくとも8文字を使用してください",
      passwordFormatValidation: {
        mainMessage: "次のうち少なくとも3つを含めてください",
        useNumberMessage: "数字（1, 2, 3 ...）",
        specialCharacterMessage: "記号（！、@、＃...）",
        uppercaseCharacterMessage: "大文字（A-Z）",
        lowecaseCharacterMessage: "小文字（a-z）",
      },
    },
    conversation: {
      inputMessage: "チャンネルへのメッセージ",
      uploadFile: "ファイルアップロード",
      includeFile: "ファイルも削除する",
      edited: "編集済",
      messageDeleteConfirmationDialogHeader: "メッセージを削除",
      messageDeleteConfirmationMessage:
        "このメッセージを本当に削除しますか？ この作業は元に戻せません。",
      messageDelete: "(このメッセージは削除されました)",
      fileDelete: "(ファイルが削除されました)",
      messageAndFileDelete: "(このメッセージとファイルは削除されました)",
    },
    channel: {
      tabs: {
        conversation: "会話",
        files: "ファイル",
        status: "ステータス",
        members: "メンバー",
      },
      status: {
        select: "ステータスを選んでください",
        statusConfirmMessage:
          "キーが変更されました。設定したステータスは初期化されます。よろしいですか？",
      },
    },
    files: {
      fromDate: "開始日",
      toDate: "終了日",
      fileName: "ファイル名",
      fileType: "ファイルタイプ",
      fileSize: "ファイルサイズ",
      uploadedOn: "更新日時",
      uploadedBy: "更新者",
      uploadUserName: "更新者名",
      tags: "タグ",
      fileDeletionConfirmationMessage: "このファイルを本当に削除しますか？",
      fileDeleteConfirmationDialogHeader: "ファイルを削除する",
      file: "ファイル",
      fileSelectionMessage: "ファイルを選んでください",
      fileUploadErrorMessage: "ファイルのアップロードに失敗しました",
      filePreviewErrorMessage: "ファイルのプレビュー表示に失敗しました",
      ScrollLoadingText: "ファイルを取得しています...",
      NoData: "何もデータが見つかりませんでした",
      RowFilterText: "サーチ",
      filters: {
        simpleTagFilter: "タグフィルター",
        simpleTagCategoryFilter: "タグカテゴリーフィルター",
        TagcategryHierarchyFilter: "多軸フィルター",
      },
      editTags: "タグを編集",
      renameFile: "ファイル名を変更する",
    },
    fileUpload: {
      title: "ファイルアップロード",
      chooseFile: "ファイルを選択",
      addMessageToFile: "メッセージ",
      addTagsToFile: "ファイルにタグを付ける",
      upload: "アップロード",
      fileUploadMaxSizeErrorMessage:
        "ファイルサイズが大きすぎてアップロードできません",
      storageLimitExceededMessage:
        "ファイルストレージの制限を超えました。 管理者に連絡してください。",
      invalidFileNameMessage:
        "ファイル名が無効です。ファイル名は255文字以下で「/」「\\」は利用できません。また特殊なファイル名「.」「..」は利用できません。",
      fileNameTooLongMessage:
        "ファイル名が長すぎます。255文字以下にしてください。",
      fileAlreadyExistModelTitle: "ファイルは既に存在します",
      fileAlreadyExistConfirmMessage:
        "ファイル{files}は既に存在します。上書きしますか？",
      sameFileNameNotAllowed: "別の名前のファイルを選択してください",
      supportedImageFiles: "ファイルタイプ： .png, .jpg",
      maxImageSize: "ファイルサイズ： 1MBまで",
    },
    filesTagEdit: {
      title: "ファイルタグの編集",
    },
    fileRename: {
      title: "ファイル名を変更する",
      fileName: "ファイル名",
    },
    members: {
      userName: "ユーザー名",
      filterUsers: "ユーザーを検索",
      successMessage: "チャンネルメンバーを更新しました",
      errorMessage: "チャンネルメンバーの更新に失敗しました",
      firstName: "名",
      lastName: "姓",
    },
    tagsSelection: {
      typeTagHere: "タグを検索",
      typeTagCategoryHere: "ここにタグのカテゴリ名を入力してください...",
    },
    tagGrid: {
      confirmDeleteMsg: "このタグカテゴリーを本当に削除しますか？",
      confirmDeleteTitle: "タグカテゴリーを削除する",
      categoryName: "カテゴリー名",
      description: "説明",
      uploadedBy: "更新者",
      uploadedOn: "更新日",
      search: "Search..",
      tag: {
        addTag: "タグを追加する",
        name: "タグ名",
        description: "説明",
        confirmDeleteTitle: "タグを削除する",
        confirmDeleteMsg: "このタグを本当に削除しますか？",
        isEnable: "有効",
        isVisible: "表示",
        uploadedBy: "更新者",
        uploadedOn: "更新日",
        notAuthorized: "タグを作る権限がありません",
      },
    },
    addTagCategory: {
      addNewTagCategory: "タグカテゴリーを追加する",
      writeTagCategoryName: "タグカテゴリー名をここに記入",
      requiredTagCategory: "タグカテゴリー名を入力してください",
      notAuthorized: "タグカテゴリーを作る権限がありません",
    },
    tags: {
      categoryDeleteMessage:
        "このタグカテゴリーは使用されています。削除しますか？",
      categoryNameConfirmMessage:
        "確認のためタグカテゴリー名を入力してください。",
      categoryNameMismatchAlertMessage: "カテゴリー名が誤っています。",
      tagInUseMessage: "このタグは使用されています。削除しますか？",
      addNewTagCategory: "タグカテゴリーを追加する",
    },
    space: {
      spaceSwitchErrorMessage: "スペースを切り替えます。よろしいですか？",
      spaceDialogHeader: "スペースを切り替える",
    },
    newMessage: "新しいメッセージ",
    yesterday: "昨日",
    today: "今日",
    sunday: "日曜日",
    monday: "月曜日",
    tuesday: "火曜日",
    wednesday: "水曜日",
    thursday: "木曜日",
    friday: "金曜日",
    saturday: "土曜日",
    january: "1月",
    february: "2月",
    march: "3月",
    april: "4月",
    may: "5月",
    june: "6月",
    july: "7月",
    august: "8月",
    september: "9月",
    october: "10月",
    november: "11月",
    december: "12月",
    th: "日",
    st: "日",
    nd: "日",
    rd: "日",
    revokeToken: {
      menuMessage: "トークンの取り消し",
      confirmTitle: "トークンの取り消し",
      confirmMessage:
        "トークンを無効にすると、以前のセッションや他のデバイスで開いていたアプリケーションがすべてログアウトされます。",
      confirmButtonText: "取り消す",
      successMessage: "トークンが取り消されました。",
      errorMessage: "トークンの取り消しに失敗しました。",
      noDevicesFound: "デバイスが見つかりません。",
    },
  },
};